import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/PageTemplate/index.ts";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Websites`}</h2>
    <ul>
      <li parentName="ul">{`Native Languages of the Americas. `}<em parentName="li">{`Tutelo/Saponi Indian Language`}</em>{`. 2015.`}<br /><a parentName="li" {...{
          "href": "http://www.native-languages.org/tutelo.htm"
        }}>{`http://www.native-languages.org/tutelo.htm`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      